<template>
    <div class="usering">
      <div class="container">
    <h1>后台管理</h1>
    <div class="stat">
      <div class="stat-item">
        <div class="number">{{ orderNum }}</div>
        <div class="label">总订单数</div>
      </div>
      <div class="stat-item">
        <div class="number">{{ userNum }}</div>
        <div class="label">总用户数</div>
      </div>
      
    </div>
  </div>
    </div>
  </template>
    
  
  <script>
  import { onMounted, reactive, toRefs } from "vue";
  import { getCount } from "../request/api";
  //import Header from '../components/header.vue'
  export default {
    components: {
      // Header,
    },
    setup() {
  
      let data = reactive({
        orderNum:"500",
        userNum:"1000",
        arr:[]
       
        
      });
  
      //订单商品数据请求
      const getcount = async () => {
        const res = await getCount();
        if (res.status === 200 && res.data.status === 1) {
          data.arr = res.data.data;
          console.log("开始输出")
          console.log(data.arr)
          data.orderNum=data.arr.orderCount;
          data.userNum=data.arr.userCount;
       
  
        }
      };
  
  
      onMounted(() => {
        getcount();
      });
  
  
      return {
        ...toRefs(data),
        getcount,
  
      }
    },
  
  };
  </script>
    
  <style>
  .usering {
    position: absolute;
    top: 60px;
    left: 200px;
    right: 0;
    padding: 10px 0;
    margin: 0 auto;
    max-width: 1500px;
  
  }
  .pasw{
    padding-left: 80px;
  }
  .el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
body {
      background-color: #f7f7f7;
      font-family: Arial, sans-serif;
    }
    
   
    
    h1 {
      color: #333;
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .stat {
    
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    
    .stat-item {
      flex-basis: 45%;
      padding: 10px;
      background-color: #e9e9e9;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    
    .number {
      font-size: 32px;
      font-weight: bold;
      color: #333;
      margin-bottom: 5px;
    }
    
    .label {
      color: #666;
      font-size: 14px;
    }
  </style>
    