<template>
  <div class="usering">
    <h3>提现中心</h3>
    <el-table :data="arr" border style="width: 90%; ">
      <el-table-column prop="id" label="编号" align="center" />
      <el-table-column prop="sid" label="用户" align="center" />、
      <el-table-column prop="pay_account" label="支付用账号" align="center" />、
      <el-table-column prop="pay_name" label="姓名" align="center" />
      <el-table-column prop="amount" label="提现金额" align="center" />
      <el-table-column prop="transactio_date" label="提交时间" align="center" />
      <el-table-column prop="state" label="当前状态" align="center" />
      <el-table-column label="修改功能" align="center">
        <template #default="scope">
          <el-button type="primary" :loading="scope.arr" @click="update(scope.row.id,scope.row.sid,scope.row.amount)">修改状态</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="dialogFormVisible" title="修改审核状态" width="500">
      <el-select align-center  v-model="value" placeholder="Select" size="large" style="width: 240px">
            <el-option v-for="item in options" :key="item.value"  :value="item.value" />
          </el-select>

      <template #footer>
        <div class="dialog-footer">

          
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="update_State()">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- <div class="page">
      <el-pagination class="page" background layout="prev, pager, next" :total="1000" />
    </div> -->
  </div>
</template>
    
  
<script>
import { onMounted, reactive, toRefs } from "vue";
import { getQuery_Balance,Update_State } from "../request/api";
import { ElMessage } from 'element-plus'
//import Header from '../components/header.vue'
export default {
  components: {
    // Header,
  },
  setup() {
    let data = reactive({

      orderListed: [],
      text: "测试",
      arr: [],
      dialogFormVisible: false,
      region: '',
      value: "审核中",
      state:"",
      userId:"",
      sid:"",
      amount:"",
      options: [
        {
          value: '审核中'
        },
        {
          value: '提现成功',

        },
        {
          value: '提现失败',

        },
      ]
    });

    



    //订单商品数据请求
    const getbalance = async () => {
      const res = await getQuery_Balance();
      console.log("开始输出1")
      if (res.status === 200 && res.data.status === 1) {
        data.arr = res.data.data;
        console.log("开始输出")
        console.log(data.arr)

      }
    };


      //修改提现状态去
      const update_State = async () => {
        if(data.value=="审核中"){
          data.state="1";
        }else if(data.value=="提现成功"){
          data.state="2";
        }else{
          data.state="3";
        }
        console.log(data.state)
        console.log(data.Id)
        const params = {
        "id": data.userId,
        "state": data.state,
         "sid":data.sid,
         "amount":data.amount

      }

      const res = await Update_State(params);
      if (res.status === 200 && res.data.status === 1) {
        console.log("开始输出")
        ElMessage({
                message: '修改成功',
                type: 'success',
            })
        data.dialogFormVisible = false;
        getbalance();
      }else{
        ElMessage({
                message: '修改失败',
                type: 'fail',
            })
      }
    };


    //修改状态功能
    const update = (id,sid,amount) => {
      console.log("修改提现状态")
      data.dialogFormVisible = true;
      data.userId=id;
      data.sid=sid;
      data.amount=amount;

    }

    onMounted(() => {
      getbalance();
    });


    return {
      ...toRefs(data),
      update,
      update_State,


    }
  },

};
</script>
    
<style>
.usering {
  position: absolute;
  top: 60px;
  left: 200px;
  right: 0;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 1500px;

}
</style>
    