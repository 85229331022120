<template>
  <div class="usering">
    <h3>用户中心</h3>
    <el-table :data="arr" border style="width: 90%; ">
      <el-table-column prop="id" label="编号" align="center" />
      <el-table-column prop="username" label="用户名" align="center" />
      <el-table-column prop="balance" label="余额" align="center" />

      <el-table-column prop="createTime" label="创建时间" align="center" />
      <el-table-column label="修改功能" align="center">
        <template #default="scope">
          <el-button type="primary" :loading="scope.arr" @click="toCart(scope.row.username)">修改密码</el-button>
          <el-button type="primary" :loading="scope.arr" @click="toCart2(scope.row.username)">修改余额</el-button>
        </template>

      </el-table-column>
  
    </el-table>
    <el-dialog v-model="dialogFormVisible" center title="修改密码" width="500" height="400">
        <el-input class="pasw" v-model="paww" style="width: 340px" placeholder="请输入新密码" :suffix-icon="Calendar" />

        <template #footer>
          <div class="dialog-footer">

            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button type="primary" @click="update_paw()">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog v-model="dialogFormVisible2" center title="修改余额" width="500">
        <el-input class="balance" v-model="balance" style="width: 340px" placeholder="修改余额" :suffix-icon="Calendar" />

        <template #footer>
          <div class="dialog-footer">

            <el-button @click="dialogFormVisible2 = false">取消</el-button>
            <el-button type="primary" @click="update_Balance()">
              确认
            </el-button>
          </div>
        </template>
      </el-dialog>
    <!-- <div class="page">
      <el-pagination class="page" background layout="prev, pager, next" :total="1000" />
    </div> -->
  </div>
</template>
  

<script>
import { onMounted, reactive, toRefs } from "vue";
import { Update_User, getQuery_User } from "../request/api";
import { ElMessage } from 'element-plus'
//import Header from '../components/header.vue'
export default {
  components: {
    // Header,
  },
  setup() {

    let data = reactive({
      paww: '',
      username:"",
      balance:"",
      orderListed: [],
      text: "测试",
      arr: [],
      dialogFormVisible: false,
      dialogFormVisible2: false,
      value: "审核中",
     
    });

    //记录用户点开弹窗时的操作用户
    const toCart = (username) => {
      console.log(username)
      data.dialogFormVisible = true
      data.username=username
    
    };
    //记录用户点开弹窗时的操作用户
    const toCart2 = (username) => {
      console.log(username)
      data.dialogFormVisible2 = true
      data.username=username
    
    };


    //订单商品数据请求
    const getUsers = async () => {

      const res = await getQuery_User();
      if (res.status === 200 && res.data.status === 1) {
        data.arr = res.data.data;
        console.log("开始输出")
      
        
      }
    };

    //修改//修改密码功能1
    const update_paw = async () => {
      const params = {
        "username": data.username,
        "password": data.paww,

      }
      const res = await Update_User(params);
      if (res.status === 200 && res.data.status === 1) {
        data.arr = res.data.data;
        console.log("开始输出")
        ElMessage({
                message: '修改成功',
                type: 'success',
            })
            data.dialogFormVisible = false
            getUsers();
      }
    };
    
     //修改//修改用户余额功能2
     const update_Balance = async () => {
      const params = {
        "username": data.username,
        "balance": data.balance,

      }
      const res = await Update_User(params);
      if (res.status === 200 && res.data.status === 1) {
        data.arr = res.data.data;
        console.log("开始输出")
        ElMessage({
                message: '修改成功',
                type: 'success',
            })
            data.dialogFormVisible2 = false
            getUsers();
      }
    };
    

    onMounted(() => {

      getUsers();
    });


    return {
      ...toRefs(data),
      getUsers,
      toCart,
      update_paw,
      update_Balance,
      toCart2,



    }
  },

};
</script>
  
<style>
.usering {
  position: absolute;
  top: 60px;
  left: 200px;
  right: 0;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 1500px;

}

.pasw {
  padding-left: 80px;
}
</style>
  