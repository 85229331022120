<template>
    <div id="backcont">
        <div class="meituan-content">
            <div class="login-cont">
                <div class="meituan-title">返利网系统后台</div>
                <div class="meituan-user">
                    <p>账号</p>
                    <el-input clearable v-model="account" class="inptflex" placeholder="请输入账号" />
                </div>
                <div class="meituan-user">
                    <p>密码</p>
                    <el-input v-model="password" show-password class="inptflex" placeholder="请输入密码" />
                </div>
                <!-- 登录和注册按钮的切换 -->

                <!-- 登录或注册按钮提交 -->
                <el-button type="success" :loading="load" @click="getLogin()" class="meituan-btn">登录</el-button>
            </div>


        </div>
    </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { login } from "../request/api";
import { ElMessage } from 'element-plus'
export default {
    components: {

    },
    setup() {
        const router = useRouter();
        let data = reactive({
            orderListed: [],
            account: "",
            password: "",
        });
        const open2 = () => {
            ElMessage({
                message: '登录成功',
                type: 'success',
            })
        };
     
        const getLogin = async () => {
            const params = {
         "username": data.account,
          "password": data.password,

      }

            const res = await login(params);
            if (res.status === 200 && res.data.status === 1) {
                console.log("打印数据");
                console.log(res.data.data);
                open2();
                localStorage.setItem('username', res.data.data.username);
                localStorage.setItem('isLogin', '1');
                //localStorage.setItem("isLogin","1")
                router.push("./order")
            } else {
                ElMessage({
                message: res.data.msg,
                type: 'warning',
            })

            }
        };
        //跳转购物车
        const toCart = () => {
            console.log("理论");
        };
        onMounted(() => {
            toCart();
        });

        return {
            ...toRefs(data),
            getLogin,
        }
    },

};

</script>

<style>
#backcont {
    background-image: url(../assets/bg.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    moz-background-size: cover;
    min-height: 100vh;
}

.meituan-content {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-cont {
    width: 500px;
    height: 300px;
    background: #FFFFFF;
    border-radius: 5px;
}

.meituan-title {
    text-align: center;
    color: #000000;
    font-size: 30px;
    padding-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.meituan-user {
    width: 400px;
    margin: 0 auto;
    padding-top: 30px;
    height: 40px;
    display: flex;
    align-items: center;
}

.meituan-user p {
    width: 50px;
    color: #000000;
    font-size: 16px;
}

.inptflex {
    flex: 1;
}

.reg-view {
    width: 400px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    padding-top: 10px;
}

.reg-view p {
    cursor: pointer;
    display: contents;
}

.meituan-btn {

    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 140px;
}
</style>