import { createRouter,createWebHistory } from "vue-router";
import myOrder from '../pages/MyOrder.vue';
import myUser from '../pages/MyUser.vue';
import myManage from '../pages/MyManage.vue';
import myBalance from '../pages/MyBalance.vue';
import myLogin from '../pages/MyLogin.vue';
import myIndex from '../pages/MyIndex.vue';
import { ElMessage } from 'element-plus'
const router = createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:'/',
            component:myLogin,
        },
        {
            path:'/login',
            component:myLogin,
        },
        {
            path:'/manage',
            component:myManage,
            meta: {
                isAuth: true,
              },
            children:[
                   {
                      path:'/order',
                      component:myOrder,
                      meta: {
                        isAuth: true,
                      },
                   },  {
                    path:'/user',
                    component:myUser,
                    meta: {
                        isAuth: true,
                      },
                 }, {
                    path:'/balance',
                    component:myBalance,
                    meta: {
                        isAuth: true,
                      },
                 }, {
                  path:'/index',
                  component:myIndex,
                  meta: {
                      isAuth: true,
                    },
               }
                

            ]
        },
    ]
});

router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    if (localStorage.isLogin === '1') {
      next();
    } else {
        ElMessage.error('请先登录.')
      router.push('./login');
    }
  } else {
    next();
  }
});



export default router;
