<template>
    <div class="ordering">
        <h3>订单中心</h3>
        <el-table :data="arr" border style="width: 90%; ">
            <el-table-column prop="id" label="编号" width="80"  align="center"/>
            <el-table-column label="图片" width="120" align="center">
                <template #default="scope">
                        <el-image style="width: 80px; height: 80px" :src="scope.row.item_img" :fit="fit"
                        align="center" 
                        :preview-src-list="[scope.row.image]" 
                         />
                </template>
            </el-table-column>
            <el-table-column prop="order_title" label="商品标题" min-width="180px" align="center"/>
            <el-table-column prop="pay_price" label="付款金额" width="120" align="center"/>
            <el-table-column prop="jtk_share_fee" label="佣金" width="120"  align="center"/>
            <el-table-column prop="sid" label="用户" width="120" align="center" />
            <el-table-column prop="pay_time" label="支付时间" width="120" align="center" />

        </el-table>
        <div class="page">
            <el-pagination class="page" background layout="prev, pager, next" 
            :total="total" 
            :page-size="10"
            @current-change="currentchange"
            :hide-on-single-page="true"

            />
             </div>
    </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import { getQuery_Order } from "../request/api";
//import Header from '../components/header.vue'
export default {
    components: {
        // Header,
    },
    setup() {
        let data = reactive({

            orderListed: [],
            text: "测试",
            arr:[],
            total:15,

        });

        //跳转购物车
        const toCart = () => {
            console.log("理论");
        };

        const currentchange =(e)=>{
           console.log(e)
           getorders(e,10);
        }

   //订单商品数据请求
   const getorders = async (pageNum,pageSize) => {
      const res = await getQuery_Order(pageNum,pageSize);
      console.log("开始输出1")
      if (res.status === 200 && res.data.status === 1) {
        data.arr = res.data.data.list;
        console.log("开始输出");
        console.log(data.arr);
        data.total=res.data.data.total;
    
      }
    };




        onMounted(() => {
            toCart();
            getorders(1,10);
        });


        return {
            ...toRefs(data),
            currentchange


        }
    },

};
</script>
<style >
.ordering {
    position: absolute;
    top: 60px;
    left: 200px;
    right: 0;
    padding: 10px 0;
    margin: 0 auto;
    max-width: 1500px;
    

}
.el-pagination {
	text-align: center;
	margin: 30px 0;
}

</style>