import axios from 'axios';

const api = axios.create({
  baseURL: 'http://flw.qz1234.cn',
  timeout: 5000,
});

api.interceptors.request.use(
  (config) => {
    const username = localStorage.getItem('username');
    if (username) {
      config.headers['X-Username'] = username;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
