<template>
    <div class="manage_page fillcontain">
        <Header></Header>
            <div class="page" style="height: 100%;">
                    <el-menu :default-active="defaultActive" class="menu" style="min-height: 80%;width: 300px;" theme="dark"  router>
                        <el-menu-item index="index"><i class="el-icon-document-copy"></i>
                            <el-icon><Comment /></el-icon>
                            首页</el-menu-item>
                        <el-menu-item index="order">
                            <i class="el-icon-goods"></i>
                            <el-icon><CircleCheckFilled /></el-icon>
                            订单管理</el-menu-item>
                        <el-menu-item index="user">
                            <i class="el-icon-menu">
                            </i><el-icon><Avatar /></el-icon>
                            用户管理</el-menu-item>
                        <el-menu-item index="balance"><i class="el-icon-document-copy"></i>
                            <el-icon><Comment /></el-icon>
                            提现管理</el-menu-item>
                        
                    </el-menu>
                    <router-view></router-view>
            </div>
    </div>
    </template>
    <script>
    import { onMounted, reactive, toRefs } from "vue";
    import Header from '../components/header.vue'
    export default {
        components: {
            Header,
        },
        setup() {
            let data = reactive({
                orderListed: [],
                text: "测试"
            });
    
            //跳转购物车
            const toCart = () => {
                console.log("理论");
            };
    
     
     
    
            onMounted(() => {
                toCart();
            });
    
    
            return {
                ...toRefs(data),
                
    
            }
        },
    
    };
    </script>
    <style >
.page{
    margin-top: 20px;
}


</style>