//import axios from './request';
import api from '../axios';

// // 首页数据接口
// export const getHomeData = () => api.get('/mock/home.json');

// // 店铺数据接口
// export const getStoreData = () => api.get('/mock/store.json');

//电商聚合商品列表API接口
export const getQuery_Order = (pageNum,pageSize) => api.get(`http://flw.qz1234.cn/admin/ordersList?pageNum=${pageNum}&pageSize=${pageSize}`);

// 用户登录接口
export const login = (params) => api.post('http://flw.qz1234.cn/admin/login',params);

export const getQuery_User = () => api.get(`http://flw.qz1234.cn/admin/userList`);
//提现记录
export const getQuery_Balance = () => api.get(`http://flw.qz1234.cn/admin/balance`);


//管理员更新用户信息
export const Update_User = (params) => api.post('http://flw.qz1234.cn/admin/changeUser',params);


//更新提现状态
export const Update_State = (params) => api.post('http://flw.qz1234.cn/admin/updateState',params);

//获取订单和用户总数


export const getCount = () => api.get(`http://flw.qz1234.cn/admin/count`);